import styles from "./forgot-password.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import WCardTitle from "../../../components/card-title/card-title";
import WTextButton from "../../../components/text-button/text-button";
import WInput from "../../../components/input/input";
import WButton from "../../../components/button/button";
import { globalColors } from "../../../style/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import authSerivce from "../../../services/auth.service";
import { useEffect, useState } from "react";
import { WCircularProgress } from "../../../components/progress/circular/circular-progress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import forgotpass from "../../../assets/img/forgotpass.svg"

import CooldownTimer from "../../../components/countdown-timer/countdown-timer";
function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [step, setStep] = useState<string>(localStorage.getItem("step") || "0");

  const [countdown, setCountdown] = useState<number>(
    JSON.parse(localStorage.getItem("time") || "0") || 0
  );
  const [requestSentRecently, setRequestSentRecently] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (step === "0") {
      localStorage.removeItem("email");
      localStorage.removeItem("time");
    }
    setCountdown(JSON.parse(localStorage.getItem("time") || "0") || 0);

    const currentDateTimestamp = Date.now();

    const timeDiffInSeconds = (currentDateTimestamp - countdown) / 1000;

    if (timeDiffInSeconds < 120) {
      setRequestSentRecently(true);
      setCountdown(countdown);
    }
  }, [step, localStorage.getItem("step")]);

  const handleCooldownEnd = (ended: boolean) => {
    setRequestSentRecently(!ended);
  };

  const formikEmail = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values, { resetForm }) => {
      setRequestSentRecently(true);

      setStep("1");
      localStorage.setItem("step", "1");
      localStorage.setItem("email", values.email);

      authSerivce
        .handleForgetPassword(values)
        .then((res: any) => {
          localStorage.setItem("step", "2");
          localStorage.setItem("time", new Date().getTime().toString());
          setCountdown(new Date().getTime());
          setStep("2");
        })
        .catch((err: any) => {
          setErrorMessage("Kayitli Email bulunamadi.");
          setStep("0");
          localStorage.setItem("step", "0");
        });
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email"),
    }),
  });

  const sendAgain = () => {
    setLoading(true);
    authSerivce
      .handleForgetPassword({ email: localStorage.getItem("email") })
      .then((res: any) => {
        setRequestSentRecently(true);
        setCountdown(new Date().getTime());
        localStorage.setItem("time", new Date().getTime().toString());
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("err", err);
        setLoading(false);
      });
  };

  return (
    <>
      <WModalCard borderRadius="10px" padding="20px 25px" width="30%">
        {step !== "2" && (
          <div className={styles["forgot-password"]}>
            <div className="flex flex-col items-center gap-2">
            <img src={forgotpass} alt="forgotpass" />
            <WCardTitle  margin="0" fontSize="20px">
              Parolanızı mı Unuttunuz?
            </WCardTitle>
            </div>

            <form className={styles["forgot-password-form"]}>
              <div className={styles["input-container"]}>
                <WInput
                  type="text"
                  value={formikEmail.values.email}
                  onChange={formikEmail.handleChange("email")}
                  placeholder="E-posta"
                ></WInput>
                {formikEmail.touched.email && formikEmail.errors.email ? (
                  <span className="error">{formikEmail.errors.email}</span>
                ) : null}
              </div>
              <WButton
                type="primary"
                onClick={formikEmail.handleSubmit}
                bgHover="rgb(240 71 12 / 39%)"
                borderRadius="5px"
              >
                {step === "1" ? (
                  <WCircularProgress sx={{ color: "white" }} size="2rem" />
                ) : (
                  <span>Devam Et</span>
                )}
              </WButton>
            </form>
          </div>
        )}

        {step === "2" && (
          <div className={styles["information"]}>
            <span>
              Sifre yenileme linki mailinize gonderilmistir, mailinizi kontrol
              edebilirsiniz
            </span>
            <CheckCircleIcon />
            <div className={styles["send-again"]}>
              {requestSentRecently && (
                <span>
                  <CooldownTimer
                    time={countdown}
                    cooldownPeriod={2}
                    onCooldownEnd={handleCooldownEnd}
                  />
                </span>
              )}

              <WTextButton
                underline
                color={globalColors.primary}
                onClick={sendAgain}
                disabled={requestSentRecently}
              >
                {loading ? <WCircularProgress /> : <span>Tekrar Gonder</span>}
              </WTextButton>
            </div>
          </div>
        )}
      </WModalCard>
    </>
  );
}

export default ForgotPassword;
