import { Navigate } from "react-router-dom";
import authSerivce from "../services/auth.service";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Home } from "@mui/icons-material";
import { FC } from "react";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user.user);

  if (!user._id) {
    return <Navigate to="/login" replace />;
  }

  return <> {children} </>;
};

export default ProtectedRoute;
