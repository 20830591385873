import { useEffect, useRef, useState } from "react";
import styles from "./session.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import videojs from "video.js";
import "videojs-vr";
import "../../../node_modules/video.js/dist/video-js.css";
import "../../../node_modules/videojs-vr/dist/videojs-vr.css";

import { WCircularProgress } from "../../components/progress/circular/circular-progress";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { iSessionTopBarRightItem } from "../../interfaces/session-top-bar-right-item";
import SessionTopBar from "../../prefabs/session/top-bar/top-bar";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { WDialog } from "../../components/dialog/dialog";
import TweenService from "../../services/tween.service";
import { Sessions, Video_Metadata } from "../../services/spica/bucket/bucket";
import sessionService from "../../services/session.service";
import PlayerService from "../../services/player.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userSerivce from "../../services/user.service";
import { WMenu } from "../../components/menu/menu";
import { WMenuItem } from "../../components/menu-item/menu-item";
import Library from "../../prefabs/library/library";
import WGlobalDialog, {
  IWGlobalDialog,
} from "../../prefabs/global-dialog/global-dialog";

function Session() {
  const [session, setSession] = useState<Sessions>();
  const [loading, setLoading] = useState(true);
  const [menuOpened, setMenuOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFinishSessionDialogVisible, setIsFinishSessionDialogVisible] =
    useState(false);
  const [isSessionFinishedDialogVisible, setIsSessionFinishedDialogVisible] =
    useState(false);
  const [isPatientKickedDialogVisible, setIsPatientKickedDialogVisible] =
    useState(false);
  const [patientFocusedPointsInterval, setPatientFocusedPointsInterval] =
    useState<NodeJS.Timer>();
  const [isSessionEndSoonPopupVisible, setIsSessionEndSoonPopupVisible] =
    useState(false);
  const [
    isSessionCountFinishedPopupVisible,
    setIsSessionCountFinishedPopupVisible,
  ] = useState(false);
  const [isVideoLibraryVisible, setIsVideoLibraryVisible] = useState(false);
  const [clipboardTooltip, setClipboardTooltip] = useState(
    "Seans bağlantı linkini kopyala"
  );

  const [isRequestNeded, setIsRequestNeded] = useState<boolean>(false)

  const [title, setTitle] = useState<string | null>(null);

  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const navigateToHome = () => {
    if (isPatient) {
      //   navigate("/landing-page-url");
    } else {
      navigate("/");
      sessionService.patch({ status: false });
    }
  };
  const user = useSelector((state: RootState) => state.user.user);

  const sessionUrl = window.location.href;
  const isPatient = Object.keys(user).length === 0;

  const getPermission = async () => {
    try {
      //@ts-ignore
      const permission = await DeviceMotionEvent.requestPermission();
      if (permission !== "granted") {
        console.warn("Permission to access device motion was denied.");
      }
    } catch (error) {
      setIsRequestNeded(true);
      console.error("Error requesting device motion permission:", error);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpened(false);
  };

  const handleVideoChangeClick = () => {
    handleMenuClose();
    setIsVideoLibraryVisible(true);
  };

  const changeSessionVideo = (video: Video_Metadata) => {
    setIsVideoLibraryVisible(false);
    const isSameVideo = PlayerService.currentSrcId == video._id;
    if (isSameVideo) return;

    const videos = sessionService.session?.videos;
    const indexOfVideoId = videos?.indexOf(video._id as string);

    if (indexOfVideoId !== -1) {
      videos?.splice(indexOfVideoId!, 1);
    }

    videos?.push(video._id as string);

    const patchObject: Sessions = {
      videos: videos,
      video_changed_at: new Date(),
      current_video: video._id,
      video_state: {
        is_playing: false,
        user_seconds: 0,
      },
    };

    if (!isPatient || !session?.started_at) {
      const viewHistoryOfVideo = getViewHistoryOfVideo();
      const viewingHistory = session?.viewing_history || [];

      viewingHistory.push(viewHistoryOfVideo);
      patchObject["viewing_history"] = viewingHistory;
    }

    sessionService.patch(patchObject);
  };

  const getViewHistoryOfVideo = () => {
    const session = sessionService.session;
    const diff =
      new Date().getTime() -
      new Date(
        (session?.video_changed_at || session?.started_at) as string
      ).getTime();
    const video = PlayerService.currentSrcId!;
    return {
      duration_in_ms: diff,
      video,
    };
  };

  const pauseVideo = () => {
    PlayerService.player.currentTime(
      sessionService.session?.video_state?.user_seconds || 0
    );
    PlayerService.player.pause();
  };
  const playVideo = () => {
    PlayerService.player.play();
    setTimeout(() => PlayerService.player.play(), 200);
  };

  const updateDoctorView = (points: Sessions["patient_focused_points"]) => {
    let cloneVector = PlayerService.player.vr().camera?.position.clone();
    if (!cloneVector) return;

    cloneVector.x = points?.x || 0;
    cloneVector.y = points?.y || 0;
    cloneVector.z = points?.z || 0;

    TweenService.animateVector3(
      PlayerService.player.vr().camera?.position,
      cloneVector,
      300
    );
  };

  const handleVideos = async (session: Sessions) => {
    if (!session?.videos?.length) return;

    const currentVideoIdOfSession =
      session.current_video || session.videos[session.videos.length - 1];
    const shouldLoadVideo =
      !sessionService.sessionVideos?.length ||
      !PlayerService.currentSrcId ||
      PlayerService.currentSrcId != currentVideoIdOfSession;

    if (shouldLoadVideo) {
      const fetchedVideos = await sessionService.getSessionVideos(
        session.videos
      );
      sessionService.sessionVideos = fetchedVideos;
      const video = session.current_video
        ? fetchedVideos.find((vid) => vid._id == session.current_video)!
        : fetchedVideos[fetchedVideos.length - 1];

      setTitle(video?.title!);
      setLoading(false);
      initializeVideo(video);
    }
  };

  const kickPatientFromSession = () => {
    setIsPatientKickedDialogVisible(true);
    sessionService.realtimeConnectionSubscription.unsubscribe();
    clearInterval(patientFocusedPointsInterval);
  };

  const finishSession = () => {
    const diffFromSessionStart =
      new Date().getTime() -
      new Date(sessionService.session?.started_at as string).getTime();
    const shouldDecreaseRemainingSession =
      diffFromSessionStart >
      (getConfigValueByKey("min_session_duration_in_ms", true) as number);

    if (shouldDecreaseRemainingSession) {
      decreaseUserRemainingSession();
    }

    let viewingHistory;

    const viewHistoryOfVideo = getViewHistoryOfVideo();

    viewingHistory = session?.viewing_history || [];
    viewingHistory.push(viewHistoryOfVideo);

    sessionService.patch({
      end_at: new Date(),
      viewing_history: viewingHistory,
      status: false,
    });
  };

  const handleSession = async (session: Sessions) => {
    const registrationId = localStorage.getItem("registration_id");
    const shouldUpdatePatientRegistrationId =
      registrationId &&
      !sessionService.isPatientRegistrationIdUpdated &&
      isPatient;

    const isSessionEnded = session.end_at != null;

    if (isSessionEnded) {
      setIsSessionFinishedDialogVisible(true);
      return;
    }

    if (shouldUpdatePatientRegistrationId) {
      sessionService.isPatientRegistrationIdUpdated = true;
      session.patient_registration_id = registrationId;
      sessionService.patch({
        patient_registration_id: registrationId,
      });
    }

    const shouldKickPatient =
      isPatient && session.patient_registration_id != registrationId;
    const shouldSeekVideo =
      isPatient &&
      sessionService.session?.video_state?.user_seconds !=
        session.video_state?.user_seconds;

    if (shouldKickPatient) {
      kickPatientFromSession();
    }

    sessionService.session = session;
    setSession(session);
    await handleVideos(session);

    const isSessionStarted = session.started_at != null;
    const isPatientJoined = session.patient_joined_at != null;
    const isDoctorWatchingVideo = session.video_state?.is_playing;

    const waitingForPatient =
      !isSessionStarted && !isPatientJoined && isPatient;

    if (waitingForPatient) {
      sessionService.patch({
        patient_joined_at: new Date(),
      });
    }

    if (isPatient) {
      const isWatchingVideo = !PlayerService.player.paused();
      const shouldPlayVideo = isDoctorWatchingVideo && !isWatchingVideo;
      const shouldPauseVideo = !isDoctorWatchingVideo;

      if (shouldPlayVideo) {
        playVideo();
      }

      if (shouldPauseVideo) {
        pauseVideo();
      }

      if (shouldSeekVideo) {
        seekVideo(session.video_state?.user_seconds as number);
      }
    }

    if (!isSessionStarted && isPatientJoined) {
      sessionService.patch({
        started_at: new Date(),
      });
    }

    if (!isPatient) {
      updateDoctorView(session.patient_focused_points);
    }
  };

  const handlePlayPauseEvent = (isPlaying: boolean) => {
    if (isPatient) return;

    const videoState: Sessions["video_state"] = {
      is_playing: isPlaying,
      user_seconds: PlayerService.player.currentTime(),
    };
    if (isPlaying) playVideo();

    sessionService.patch({
      video_state: videoState,
    });
  };

  const handleVideoSeeked = () => {
    if (isPatient) return;
    const user_seconds = PlayerService.player.currentTime();

    sessionService.patch({
      video_state: {
        ...sessionService.session?.video_state,
        user_seconds,
      },
    });
  };

  const initializeVideo = (video: Video_Metadata) => {
    const isVideoElementMounted = videoRef.current != null;
    if (!isVideoElementMounted || !video) return;

    const configs = {
      controls: !isPatient,
      autoplay: false,
      fluid: true,
      loop: false,
    };

    let player: any = videojs(videoRef.current!, configs);
    videoRef.current?.click();

    player.src({
      src: video.content,
      type: "video/mp4",
    });

    player.vr({
      projection: "AUTO",
      motionControls: true,
    });

    player.on("pause", () => handlePlayPauseEvent(false));
    player.on("play", () => handlePlayPauseEvent(true));
    player.on("seeked", () => handleVideoSeeked());

    PlayerService.player = player;
    PlayerService.currentSrcId = video._id as string;
  };

  const hideFinishSessionDialog = () => setIsFinishSessionDialogVisible(false);
  const showFinishSessionDialog = () => setIsFinishSessionDialogVisible(true);

  const topBarRightItems: Array<iSessionTopBarRightItem> = [
    {
      icon: <FileCopyIcon />,
      onClick: () => copySessionUrlToClipboard(),
      tooltip: clipboardTooltip,
    },
    {
      icon: <MoreHorizIcon />,
      onClick: (event) => handleMenuClick(event),
    },
    {
      icon: <CancelRoundedIcon />,
      onClick: () => showFinishSessionDialog(),
      tooltip: "Seansı bitir",
    },
  ];

  const copySessionUrlToClipboard = () => {
    navigator.clipboard.writeText(sessionUrl);
    setClipboardTooltip("Kopyalandı");
    setTimeout(
      () => setClipboardTooltip("Seans bağlantı linkini kopyala"),
      1500
    );
  };

  const patchPatientFocusedPoints = () => {
    if (!PlayerService.player || !PlayerService.player.vr().camera) return;

    const { x, y, z } = PlayerService.player.vr().camera.position;

    sessionService.patch({
      patient_focused_points: {
        x,
        y,
        z,
      },
    });
  };

  const registerDevice = () => {
    const isDeviceRegistered = localStorage.getItem("registration_id");
    if (isDeviceRegistered) return;

    const uuid = uuidv4();
    localStorage.setItem("registration_id", uuid);
  };

  const getConfigValueByKey = (key: string, convertToNumber?: boolean) => {
    const value = sessionService.configs?.find(
      (config) => config.key == key
    )?.value_as_string;
    return !convertToNumber ? value : Number(value);
  };

  const checkSessionDuration = () => {
    const { configs, session, isSessionEndSoonPopupShowed } = sessionService;

    if (isSessionEndSoonPopupShowed || !configs?.length || session?.end_at)
      return;

    const diffFromSessionStart =
      new Date().getTime() - new Date(session?.started_at as string).getTime();
    const remainingTime =
      ((session?.extend_count || 0) + 1) *
        (getConfigValueByKey("session_duration_as_ms", true) as number) -
      diffFromSessionStart;
    const isSessionEnded = !session?.end_at && remainingTime < 0;

    if (isSessionEnded) {
      setIsSessionEndSoonPopupVisible(false);
      return finishSession();
    }

    const shouldShowWarningPopup =
      (getConfigValueByKey(
        "doctor_warning_time_for_session_in_ms",
        true
      ) as number) > remainingTime && !isPatient;
    if (shouldShowWarningPopup && !isSessionEndSoonPopupShowed) {
      setIsSessionEndSoonPopupVisible(true);
      sessionService.isSessionEndSoonPopupShowed = true;
    }
  };

  const decreaseUserRemainingSession = () => {
    return userSerivce.updateUser(user._id as string, {
      remaining_sessions: (user.remaining_sessions as number) - 1,
    });
  };

  const extendSession = () => {
    setIsSessionEndSoonPopupVisible(false);

    if (user.remaining_sessions) {
      decreaseUserRemainingSession();
      const extend_count = (sessionService.session?.extend_count as number) + 1;
      sessionService.patch({
        extend_count: extend_count,
      });
    } else {
      setIsSessionCountFinishedPopupVisible(true);
    }
  };

  const checkNeedPermission = () => {
    if (getMobileOperatingSystem() === "iOS") getPermission();
  };

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  };

  const seekVideo = (time: number) => {
    if (!PlayerService.player) return;
    PlayerService.player.currentTime(time);
  };

  const dialogs: IWGlobalDialog["dialogs"] = [
    {
      title: "Seansı Sonlandır",
      description: "Seans sonlandırılıyor.",
      buttons: [
        {
          children: "Onayla",
          onClick: () => {
            hideFinishSessionDialog();
            finishSession();
          },
        },
        {
          children: "İptal Et",
          onClick: () => {
            hideFinishSessionDialog();
          },
        },
      ],
      isDialogVisible: isFinishSessionDialogVisible,
      onClose: hideFinishSessionDialog,
    },
    {
      title: "Bu seans gerçekleştirilmiştir.",
      buttons: [
        {
          children: "Ana sayfa",
          onClick: navigateToHome,
        },
      ],
      isDialogVisible: isSessionFinishedDialogVisible,
      onClose: navigateToHome,
    },
    {
      title:
        "Seansa başka bir cihazdan giriş yapıldığı için oturumunuz sonlanmıştır.",
      buttons: [
        {
          children: "Ana sayfaya dön",
          onClick: navigateToHome,
        },
      ],
      isDialogVisible: isPatientKickedDialogVisible,
      onClose: navigateToHome,
    },
    {
      title: "Seansınız yakında bitecektir",
      buttons: [
        {
          children: "Tamam",
          onClick: () => setIsSessionEndSoonPopupVisible(false),
        },
        {
          children: "Seansı Uzat",
          onClick: extendSession,
        },
      ],
      isDialogVisible: isSessionEndSoonPopupVisible,
      onClose: () => setIsSessionEndSoonPopupVisible(false),
    },
    {
      title: "Seans uzatma hakkınız kalmadı",
      buttons: [
        {
          children: "Tamam",
          onClick: () => setIsSessionCountFinishedPopupVisible(false),
        },
        {
          children: "Satın Al",
          onClick: () => navigate("/subscription"),
        },
      ],
      isDialogVisible: isSessionCountFinishedPopupVisible,
      onClose: () => setIsSessionCountFinishedPopupVisible(false),
    },
    {
      title: "Seans Başlamak üzere",
      buttons: [
        {
          children: "Devam Et",
          onClick: () => {startVRMode(); setIsRequestNeded(false)},
        },
      ],
      isDialogVisible: isRequestNeded,
      onClose: () => {startVRMode(); setIsRequestNeded(false)},
    },
  ];
  useEffect(() => {
    checkNeedPermission();
    sessionService.fetchConfigs();
    registerDevice();
    const connection = sessionService.connectToSessionRealtime(
      sessionId as string
    );
    sessionService.realtimeConnection = connection;
    sessionService.realtimeConnectionSubscription = connection.subscribe(
      (data) => {
        handleSession(data);
      }
    );

    if (isPatient) {
      setPatientFocusedPointsInterval(
        setInterval(patchPatientFocusedPoints, 100)
      );
    } else {
      setInterval(checkSessionDuration, 1000);
    }

    return () => {
      sessionService.realtimeConnectionSubscription.unsubscribe();
      clearInterval(patientFocusedPointsInterval);
    };
  }, []);


  const startVRMode = () => {
    getPermission();
    
    // Play video only after user interaction
    if (PlayerService.player) {

      PlayerService.player.play().catch((error: any) => {
        console.error("Error attempting to play the video:", error);
      });
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      document.addEventListener("contextmenu", (e) => e.preventDefault());
      const disableInspectElement = (e: KeyboardEvent) => {
        if (
          e.key === "F12" ||
          (e.ctrlKey && e.shiftKey && e.key === "I") ||
          (e.ctrlKey && e.shiftKey && e.key === "J") ||
          (e.ctrlKey && e.key === "U")
        ) {
          console.log("Inspect element disabled");
          
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", disableInspectElement);
      document.addEventListener('visibilitychange', function() {
        if (document.visibilityState === 'hidden') {
          alert("Developer tools are not allowed.");
        }
      });
      return () => {
        document.removeEventListener("keydown", disableInspectElement);
      };
    }
  }, []);

  return (
    <div className={styles["container"]}>
      {loading && (
        <div className={styles["progress-container"]}>
          <WCircularProgress className={styles["circular-progress"]} />
        </div>
      )}
      <div className={styles["content"]}>
       {!isPatient && <SessionTopBar
          rightItemsVisibility={!isPatient}
          title={title as string}
          rightItems={topBarRightItems}
          onBackClick={showFinishSessionDialog}
          backIcon={<ArrowBackRoundedIcon />}
        />}
        <WMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpened}
          onClose={handleMenuClose}
        >
          <WMenuItem onClick={handleVideoChangeClick}>Video Değiştir</WMenuItem>
        </WMenu>
        <div data-vjs-player>
          <video
            muted={isPatient}
            ref={videoRef}
            preload="auto"
            className={`video-js vjs-default-skin vjs-v7 ${styles["video"]}`}
            controls
            crossOrigin="anonymous"
            playsInline={true}
          ></video>
        </div>
        <WGlobalDialog dialogs={dialogs} />
        <WDialog
          open={isVideoLibraryVisible}
          onClose={() => setIsVideoLibraryVisible(false)}
          fullWidth={true}
          maxWidth={false}
        >
          <Library onVideoClick={changeSessionVideo} />
        </WDialog>
      </div>
    </div>
  );
}

export default Session;
