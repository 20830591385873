import styles from "./home.module.scss";
import home_bg_1 from "../../assets/img/home_bg/home_bg_1.webp";
import home_bg_2 from "../../assets/img/home_bg/home_bg_2.webp";
import home_bg_3 from "../../assets/img/home_bg/home_bg_3.webp";
import home_bg_4 from "../../assets/img/home_bg/home_bg_4.webp";
import Library from "../../prefabs/library/library";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import userSerivce from "../../services/user.service";
import { setUser } from "../../redux/user/user.slice";

const backgrounds = [home_bg_1, home_bg_2, home_bg_3, home_bg_4];

function Home() {
  const navigate = useNavigate();
   const user = useSelector((state: RootState) => state.user.user);
   const dispatch = useDispatch();

   useEffect(() => {
    localStorage.removeItem("selectedPlan");
    getUser(user?._id!);
   }, []);

   const getUser = (userId: string) => {
    return userSerivce.getUser(userId).then((res) => {
      dispatch(setUser(res));
      return res;
    });
  };
   
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          {backgrounds.map((background, index) => (
            <div
              key={index.toString()}
              className={`${styles["bg"]} ${styles[`bg${index + 1}`]}`}
              style={{ backgroundImage: `url(${background})` }}
            ></div>
          ))}
          <div className={styles["header-wrap"]}>
            <div className={styles["header-text"]}>
              <div className={styles["header-title"]}>RecodinVR</div>
              <div className={styles["header-subtitle"]}>
                Sanal Gerçeklik Terapisi İle Tanışın
              </div>
            </div>
          </div>
        </div>
        <Library onVideoClick={(video) => navigate("session-preview")} />
      </div>
    </div>
  );
}

export default Home;
