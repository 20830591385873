import styles from "./library.module.scss";
import WVideoSlider from "../../prefabs/video-slider/video-slider";
import { useEffect, useState } from "react";
import {
  Sessions,
  Video_Categories,
  Video_Metadata,
  video_metadata,
} from "../../services/spica/bucket/bucket";
import { WCircularProgress } from "../../components/progress/circular/circular-progress";
import sessionService from "../../services/session.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import WButton from "../../components/button/button";
import { useNavigate } from "react-router-dom";

function Library({
  onVideoClick,
}: {
  onVideoClick: (video: Video_Metadata) => any;
}) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Video_Categories[]>([]);
  const [videos, setVideos] = useState<Video_Metadata[]>([]);
  const [liveSession, setLiveSession] = useState<
    Sessions<["patient", "videos"]>
  >({});

  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);

  const fetchVideosAndCategories = async () => {
    try {
      const res = await video_metadata.getAll({
        queryParams: {
          relation: true,
        },
      });
      const categories = extractCategoriesFromVideos(res);
      setCategories(categories);
      setVideos(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideosAndCategories();
    getLiveSessions();
  }, []);

  const extractCategoriesFromVideos = (
    videos: Video_Metadata[]
  ): Video_Categories[] => {
    const categories: Video_Categories[] = [];

    videos.forEach((video) => {
      (video.categories as Video_Categories[])?.forEach((category) => {
        if (!categories.some((cCategory) => cCategory._id === category._id)) {
          categories.push(category);
        }
      });
    });

    return categories;
  };

  const getVideosByCategory = (
    category: Video_Categories
  ): Video_Metadata[] => {
    return videos.filter((video) =>
      (video.categories as Video_Categories[])?.some(
        (vCategory) => vCategory._id === category._id
      )
    );
  };

  const getLiveSessions = async () => {
    const liveSession = await sessionService.getSessionsInformations(
      user?._id!,
      { status: true }
    );
    setLiveSession(liveSession[0]);
  };

  const joinLiveSession = async () => {
    navigate(`/session/${liveSession._id}`);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <WCircularProgress />
      ) : (
        <>
          <h1 className={styles.title}>RECODINVR KÜTÜPHANESİ</h1>
          {liveSession && (
            <div className="flex items-center justify-between rounded-xl shadow-custome px-5 py-3">
              <div className="flex items-center gap-3 rel relative">
                <h3>Devam Eden Seans:</h3>
                <span>{liveSession?.title}</span>
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                </span>
              </div>
              <div className="flex items-center gap-3">
                <h3>Danışan:</h3>
                <span>{liveSession?.patient?.name_surname}</span>
              </div>
              <WButton borderRadius="8px" onClick={joinLiveSession}>
                Giriş Yap
              </WButton>
            </div>
          )}
          <div className={styles.videos}>
            {categories.map((category, index) => (
              <div key={category._id} className={styles["video-slider"]}>
                <WVideoSlider
                  title={category.title as string}
                  videos={getVideosByCategory(category)}
                  onVideoClick={onVideoClick}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Library;
