import styles from "./create-password.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import WCardTitle from "../../../components/card-title/card-title";
import WTextButton from "../../../components/text-button/text-button";
import WInput from "../../../components/input/input";
import WButton from "../../../components/button/button";
import { globalColors } from "../../../style/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import databaseService from "../../../services/database.service";
import authSerivce from "../../../services/auth.service";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function CreatePassowrd() {
  const { key } = useParams<{ key: string }>();

  const [isRequestExist, setIsRequestExist] = useState<boolean>(true);
  const [isPasswordUpdated, setisPasswordUpdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const formikPassword = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      authSerivce
        .handleCompletePassword({
          newPassword: values.password,
          verificationCode: key,
        })
        .then((res) => {
          setisPasswordUpdated(true);
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        })
        .catch((error) => {
          setisPasswordUpdated(false);
          setLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(),
      confirm_password: Yup.string()
        .label("confirm password")
        .required()
        .oneOf([Yup.ref("password"), ""], "Şifreler eşleşmiyor"),
    }),
  });

  return (
    <>
      <WModalCard borderRadius="10px" padding="20px 25px" width="40%">
        {isRequestExist && !isPasswordUpdated && (
          <div className={styles["create-password"]}>
            <WCardTitle margin="0" fontSize="20px">
              Parolonızı Yenileyin
            </WCardTitle>

            <form className={styles["create-password-form"]}>
              <div className={styles["input-container"]}>
                <WInput
                  type="password"
                  value={formikPassword.values.password}
                  onChange={formikPassword.handleChange("password")}
                  placeholder="Yeni Şifre"
                ></WInput>
                {formikPassword.touched.password &&
                formikPassword.errors.password ? (
                  <span className="error">
                    {formikPassword.errors.password}
                  </span>
                ) : null}
              </div>
              <div className={styles["input-container"]}>
                <WInput
                  type="password"
                  value={formikPassword.values.confirm_password}
                  onChange={formikPassword.handleChange("confirm_password")}
                  placeholder="Yeni Şifreyi Tekrar Girin"
                ></WInput>
                {formikPassword.touched.confirm_password &&
                formikPassword.errors.confirm_password ? (
                  <span className="error">
                    {formikPassword.errors.confirm_password}
                  </span>
                ) : null}
              </div>
              <WButton
                type="primary"
                onClick={formikPassword.handleSubmit}
                bgHover="rgb(240 71 12 / 39%)"
                borderRadius="5px"
                disabled={loading}
              >
                {loading ? "Sifreniz Güncelleniyor..." : "Onayla"}
              </WButton>
            </form>
          </div>
        )}
        {isPasswordUpdated && (
          <div className={styles["password-updated"]}>
            <h1 className="text-white">Şifreniz Başarıyla Güncellendi</h1>
            <span className="text-white">Login Sayfasına Yönlendiriliyorsunuz...</span>
            <CheckCircleIcon />
          </div>
        )}

        {!isRequestExist && (
          <div className={styles["page-not-found"]}>
            <h1>404</h1>
            <h1>Sayfa Bulunamadı</h1>
          </div>
        )}
      </WModalCard>
    </>
  );
}

export default CreatePassowrd;
