import React, { FC, useEffect, useState } from "react";
import styles from "./session-popup.module.scss";
import WModalCard from "../../components/modal-card/modal-card";
import { Autocomplete, Modal, TextField } from "@mui/material";
import WCardTitle from "../../components/card-title/card-title";
import WButton from "../../components/button/button";
import { defaultItem, item } from "../../components/select-item/select-item";
import { useNavigate } from "react-router-dom";
import AddPatient from "../add-patient/add-patient";
import { Patients } from "../../services/interfaces";
import patientService from "../../services/patient.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { current } from "@reduxjs/toolkit";
import SessionService from "../../services/session.service";
import axios from "axios";
import { globalColors } from "../../style/colors";

interface iSessionPopupProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  haveSubscription: boolean;
  enoughSessionCount: boolean;
}

const WSessionPopup: FC<iSessionPopupProps> = ({
  open,
  setOpen,
  haveSubscription,
  enoughSessionCount,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<item>(defaultItem);
  const user = useSelector((state: RootState) => state.user.user);

  const [patients, setPatient] = useState<Patients[]>([]);

  const [openAddPatient, setOpenAddPatient] = useState(false);

  const video = useSelector(
    (state: RootState) => state.sessionVideo.sessionVideo
  );
  const handleStart = async () => {
    const data = {
      title: video?.title,
      user: user?._id,
      patient: value?._id,
      started_at: new Date(),
      videos: [`${video?._id}`],
      current_video: video?._id,
    };
    try {
      const session = await SessionService.createSession(data);
      navigate("/session/" + session._id);
    } catch (error) {}
  };

  function handleSubscription() {
    navigate("/subscription");
  }

  function handleAddPatient() {
    setOpenAddPatient(true);
  }

  const getAllPatients = async (userId: string) => {
    const patients = await patientService.getPatients(userId);
    setPatient(patients);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <div className={styles["popup"]}>
          <WModalCard
            padding="30px"
            bgColor="rgba(0, 0, 0, 0.4)"
            borderRadius="10px"
            className={styles["modal-card"]}
            type="secondary"
            children={
              haveSubscription && enoughSessionCount ? (
                <div className={styles["box"]}>
                  <WCardTitle color="#fff" fontSize="1.2rem">
                    Danışan İsmi
                  </WCardTitle>
                  <div className={styles["content"]}>
                    <div className={styles["select"]}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={patients}
                        getOptionLabel={(option) => option.name_surname!}
                        sx={{
                          width: 300,
                          border: "1px solid orange",


                          borderRadius: "20px",
                          outline: "none",

                          "& .MuiAutocomplete-endAdornment": {
                            display: "none",
                          },
                        }}
                        onChange={(event: any, option: any) => {
                          setValue(option);
                        }}
                        onOpen={() => getAllPatients(user?._id!)}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiFormLabel-root": {
                                color: "white",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: globalColors.borderColor,
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: globalColors.primary,
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: globalColors.borderColor,
                              },
                            }}
                            {...params}
                            label="Danışan Seç"
                          />
                        )}
                      />
                    </div>
                    <div className={styles["add-button"]}>
                      <WButton
                        onClick={handleAddPatient}
                        type="secondary"
                        horizontalSize={100}
                        borderRadius="5px"
                      >
                        + Yeni Danışan Ekle
                      </WButton>
                    </div>
                    <div className={styles["start-button"]}>
                      <WButton
                        onClick={handleStart}
                        disabled={!value?._id}
                        borderRadius="5px"
                        padding="5px 40px"
                      >
                        Başlayın
                      </WButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles["subscription-box"]}>
                  <h2>
                    {!haveSubscription && "Abonelik Başlatmalısınız"}
                    {!enoughSessionCount &&
                      haveSubscription &&
                      "Yeterli Seansınız Yok"}
                  </h2>
                  <div className={styles["subscription-text"]}>
                    {!haveSubscription &&
                      "Videonun tam halini görmek için lütfen abonelik başlatın."}
                    {!enoughSessionCount &&
                      haveSubscription &&
                      "Seans satın alarak videonun tam halini görebilirsiniz."}
                  </div>
                  <div className={styles["subscription-button"]}>
                    <WButton onClick={handleSubscription}>
                      {!haveSubscription && "Başlayın"}
                      {!enoughSessionCount &&
                        haveSubscription &&
                        "Seans Satın Al"}
                    </WButton>
                  </div>
                </div>
              )
            }
          />
          <AddPatient open={openAddPatient} setOpen={setOpenAddPatient} />
        </div>
      </Modal>
    </div>
  );
};

export default WSessionPopup;
