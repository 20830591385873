import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import WCardTitle from "../../../components/card-title/card-title";
import WTextButton from "../../../components/text-button/text-button";
import WInput from "../../../components/input/input";
import WButton from "../../../components/button/button";
import { globalColors } from "../../../style/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import authSerivce from "../../../services/auth.service";
import { WCircularProgress } from "../../../components/progress/circular/circular-progress";
import userSerivce from "../../../services/user.service";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/user/user.slice";
import usePasswordVisibility from "../../../hooks/usePasswordVisibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { log_in_session } from "../../../services/spica/bucket/bucket";
import { iUser } from "../../../interfaces/common";

function Login() {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visibility, showPassword } = usePasswordVisibility();

  useEffect(() => {
    localStorage.removeItem("step");
    localStorage.removeItem("email");
    localStorage.removeItem("time");
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const loginRes = await authSerivce.login(values.email, values.password);
        authSerivce.setToken(loginRes);
        const verified: any = await authSerivce.verifyToken(loginRes);

        await userSerivce.getUser(verified?.attributes?.user).then((res) => {
          hadleLoginSession(res);
          dispatch(setUser(res));
        });
        setLoading(false);
        navigate("/");
      } catch (error: any) {
        setErrorMessage("Kullanıcı adı veya şifre hatalı.");
        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Lütfen e-posta adresinizi giriniz."),
      password: Yup.string().required("Lütfen şifrenizi giriniz."),
    }),
  });

  const hadleLoginSession = async (user: iUser) => {
    const device = navigator.userAgent;
    const log = await log_in_session.getAll();
    if (log.length > 0) {
      if (log[0].device === device) {
        return;
      }
      await log_in_session.patch({
        _id: log[0]._id!,
        device,
      });
      return;
    }
    log_in_session.insert({
      user: user._id,
      device,
    });
  };

  return (
    <>
      <WModalCard
        borderRadius="10px"
        padding="20px 40px"
        type="primary"
        className={styles["login"]}
      >
        <WCardTitle margin="0">Oturum Aç</WCardTitle>
        {errorMessage && (
          <span className={styles["error-message"]}>{errorMessage}</span>
        )}
        <form className={styles["login-form"]}>
          <div className={styles["input-container"]}>
            <WInput
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              placeholder="E-posta"
            ></WInput>

            {formik.touched.email && formik.errors.email ? (
              <span className="error">{formik.errors.email}</span>
            ) : null}
          </div>
          <div className={styles["input-container"]}>
            <WInput
              type={visibility.password ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange("password")}
              placeholder="Şifre"
              id="password"
            ></WInput>
            <div className="show-password">
              {visibility.password ? (
                <VisibilityIcon
                  onClick={() => showPassword("password")}
                  sx={{ color: globalColors.lightFontColor }}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => showPassword("password")}
                  sx={{ color: globalColors.lightFontColor }}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password ? (
              <span className="error">{formik.errors.password}</span>
            ) : null}
          </div>

          <WButton
            type="primary"
            onClick={formik.handleSubmit}
            bgHover="rgb(238 61 0)"
          >
            {" "}
            <>
              {loading ? (
                <WCircularProgress sx={{ color: "white" }} size="2rem" />
              ) : (
                <>Oturum Aç</>
              )}
            </>
          </WButton>
        </form>

        <div className="flex flex-col gap-2">
          <WTextButton
            onClick={() => {
              localStorage.setItem("step", "0");
              navigate("/forgot-password");
            }}
            color={globalColors.lightFontColor}
            fontSize="0.75rem"
            hoverColor={globalColors.primary}
          >
            Parolanızı mı unuttunuz ?
          </WTextButton>

          <div className={styles["login-footer"]}>
            RecodinVR dünyasına katılmak ister misiniz ?{" "}
            <WTextButton
              color={globalColors.lightFontColor}
              fontSize="0.75rem"
              onClick={() => navigate("/register")}
              hoverColor={globalColors.primary}
            >
              {" "}
              Şimdi Kayıt Olun.
            </WTextButton>
          </div>
        </div>
      </WModalCard>
    </>
  );
}

export default Login;
