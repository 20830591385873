import { FC, useEffect } from "react";
import VideoCard from "../../components/video-card/video-card";
import styles from "./video-slider.module.scss";
import Carousel from "react-material-ui-carousel";
import { Video_Metadata } from "../../services/spica/bucket/bucket";
import { useDispatch } from "react-redux";
import { setSessionVideo } from "../../redux/session-video/session-video";

interface iVideoSliderProps {
  title: string;
  videos: any[];
  onVideoClick: (video: Video_Metadata) => any;
}

function rotateArray(arr: any[]) {
  const rotated = [];
  const length = 4;
  for (let i = 0; i < arr.length; i++) {
    if (i + length <= arr.length) {
      rotated.push(arr.slice(i, i + length));
    } else {
      rotated.push([
        ...arr.slice(i),
        ...arr.slice(0, length - (arr.length - i)),
      ]);
    }
  }
  return rotated;
}

const WVideoSlider: FC<iVideoSliderProps> = ({
  title,
  videos,
  onVideoClick,
}) => {
  const data = videos.length >= 3 ? rotateArray(videos) : [videos];

  const dispatch = useDispatch();

  useEffect(() => {
    const circles = document.getElementsByClassName("css-1m9128y");

    Array.from(circles).forEach((circle: Element) => {
      circle.setAttribute("style", "display: none");
    });
  });

  const handleSessionVideo = (video: Video_Metadata) => {
    localStorage.setItem("video", JSON.stringify(video));
    dispatch(setSessionVideo(video));
    onVideoClick(video);
  };

  return (
    <>
      <h1 className={styles["title"]}>{title}</h1>
      {
        <Carousel
          animation="slide"
          className={styles["carousel"]}
          autoPlay={false}
          NavButton={({ onClick, next }) => {
            return (
              <div onClick={() => onClick()} className={styles["nav-button"]}>
                <div
                  className={
                    styles[
                      next ? "nav-button-icon-next" : "nav-button-icon-prev"
                    ]
                  }
                ></div>
              </div>
            );
          }}
        >
          {data.map((item, index) => (
            <div key={index.toString()} className={styles["slide"]}>
              {item.map((item, i) => (
                <div key={i.toString()} className={styles["slide-item"]}>
                  <VideoCard
                    key={i}
                    title={item.subtitle}
                    backgroundImage={
                      item?.thumbnail || "https://picsum.photos/200/300"
                    }
                    duration={item.duration_seconds}
                    onClick={() => handleSessionVideo(item)}
                  />
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      }
    </>
  );
};

export default WVideoSlider;
