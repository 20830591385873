import React, { useState } from "react";
import * as Storage from "@spica-devkit/storage";
import styles from "./profile.module.scss";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import WAccountCard from "../../../components/account-card/account-card";
import WTextButton from "../../../components/text-button/text-button";
import WButton from "../../../components/button/button";
import { globalColors } from "../../../style/colors";
import WInput from "../../../components/input/input";
import { FormikProps, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router";
import AvatarPlaceholder from "../../../assets/img/avatar_placeholder.png";
import userService from "../../../services/user.service";
import { setUser } from "../../../redux/user/user.slice";
import { iUser } from "../../../interfaces/common";
import { PUBLIC_APIKEY, PUBLIC_URL } from "../../../utils/constants";
import authSerivce from "../../../services/auth.service";
import EditableInfoRow from "../../../components/editableInfoRow/editable-info-row";

interface FieldsProps {
  fieldKey: string;
  displayer: string;
  value: string | undefined;
}

function Profile() {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      name: user?.name,
      surname: user?.surname,
      phone_number: user?.phone_number,
      email: user?.email,
      bio: user?.bio,
      title: user?.title,
      avatar: user?.avatar,
    },
    onSubmit: (values: iUser) => {
      const changesMade = (Object.keys(values) as (keyof iUser)[]).some(
        (key) => values[key] !== user[key]
      );

      if (changesMade) {
        const updatedUser = { ...user, ...values };

        dispatch(setUser(updatedUser));
        try {
          userService.updateUser(user._id!, values);
        } catch (error) {}
      } else {
        console.log("No changes detected, user not updated");
      }
    },
  });

  const actions = {
    save: () => (
      <>
        <Save />
        <span className={styles["action-button"]}>Kaydet</span>
      </>
    ),
    edit: () => (
      <>
        <Edit />
        <span className={styles["action-button"]}>Düzenle</span>
      </>
    ),
  };

  const handleEditButton = () => {
    setEditable(!editable);
    if (editable) {
      formik.handleSubmit();
    }
  };

  const handleRemoveAccount = () => {
    try {
      authSerivce.removeUserAccount({ email: user?.email });
      navigate("/remove-account");
    } catch (error) {
      console.error(error);
    }
    navigate("/remove-account");
  };

  function handleProfilePhotoChange(e: any) {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setAvatar(url);
    updateProfilePhoto(file);
  }

  const updateProfilePhoto = async (file: File) => {
    Storage.initialize({
      apikey: PUBLIC_APIKEY,
      publicUrl: PUBLIC_URL,
    });

    try {
      const result = await Storage.insert(file);
      userService.updateUser(user._id!, { avatar: result.url });
      setAvatar(result.url);
      dispatch(setUser({ ...user, avatar: result.url }));
    } catch (error) {
      console.error(error);
    }
  };

  const changePassword = () => {
    navigate("/change-password");
  };

  const leftFields = [
    { fieldKey: "name", displayer: "İsim", value: user?.name },
    { fieldKey: "surname", displayer: "Soyisim", value: user?.surname },
    {
      fieldKey: "phone_number",
      displayer: "Telefon Numarası",
      value: user?.phone_number,
    },
    { fieldKey: "email", displayer: "Email Adresi", value: user?.email },
  ];

  const rightFields = [
    // { fieldKey: 'title', displayer: 'Title', value: user?.title },
    { fieldKey: "bio", displayer: "Bio", value: user?.bio },
  ];

  const renderFields = (fields: FieldsProps[]) =>
    fields.map(({ fieldKey, displayer, value }) => (
      <EditableInfoRow
        key={fieldKey}
        fieldKey={fieldKey}
        displayer={displayer}
        value={value}
        editable={editable}
        formik={formik}
      />
    ));

  return (
    <div className={styles["profile"]}>
      <WAccountCard className="flex gap-9 justify-between  items-start">
     <div className="flex gap-3">
     <div className={styles["avatar"]}>
          <img src={avatar || user?.avatar || AvatarPlaceholder} alt="avatar" />
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleProfilePhotoChange}
          />
          <label htmlFor="icon-button-file">
            <span color="primary" aria-label="upload picture">
              <Edit />
            </span>
          </label>
        </div>
        <div>
          <h3>
            {user?.name} {user?.surname}
          </h3>
          <div>{renderFields([{ fieldKey: 'title', displayer: 'Ünvan', value: user?.title }])}</div>
        </div>
     </div>
        <WButton
            type="outlined"
            borderColor={globalColors.primary}
            borderRadius="10px"
            onClick={handleEditButton}
            className={styles["edit-btn"]}
            color="white"
          >
            {editable ? actions.save() : actions.edit()}
          </WButton>
      </WAccountCard>
      <WAccountCard className={styles["personal-info-container"]}>
        <div className={styles["header"]}>
          <h3>Kişisel Bilgiler</h3>
          <WButton
            type="outlined"
            borderColor={globalColors.primary}
            borderRadius="10px"
            onClick={handleEditButton}
            className={styles["edit-btn"]}
            color="white"
          >
            {editable ? actions.save() : actions.edit()}
          </WButton>
        </div>

        <div className={styles["personal-info"]}>
          <div className="w-full flex flex-wrap justify-between">
            {renderFields(leftFields)}
          </div>
          <div className="">{renderFields(rightFields)}</div>
        </div>
      </WAccountCard>
      <WAccountCard className="flex justify-center">
        <WTextButton
          onClick={() => {
            changePassword();
          }}
        >
          <span className={styles["change-password"]}>Parola Değiştir</span>
        </WTextButton>
      </WAccountCard>
    </div>
  );
}

export default Profile;
