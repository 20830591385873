import React, { FC } from "react";
import styles from "./video-card.module.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import utilityService from "../../utils/utility.service";

interface iVideoCardProps {
  title: string;
  backgroundImage: string;
  duration: number;
  onClick?: () => void;
  visible?: boolean;
}

const WVideoCard: FC<iVideoCardProps> = ({
  title,
  backgroundImage,
  duration,
  onClick,
  visible = true,
}) => {
  return (
    <div
      className={styles["container"]}
      onClick={onClick}
      style={{ display: `${visible ? "block" : "none"}` }}
    >
      <img
        src={backgroundImage}
        alt={title}
        className={styles["background-image"]}
      />
      <div className={styles["title-box"]}>
        <div className={styles["title-text"]}>
          <div className={styles["title-text-left"]}>{title}</div>
          <div className={styles["title-text-right"]}>
            <ArrowBackIosNewIcon />
          </div>
        </div>
        <div className={styles["title-duration"]}>
          <div className={styles["title-duration-line"]}></div>

          <div className={styles["title-duration-text"]}>
            {" "}
            {utilityService.formatDate(duration || 0)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WVideoCard;
