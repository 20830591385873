import { Link } from "react-router-dom";
import WLogo from "../../components/logo/logo";
import styles from "./footer.module.scss";
import { terms } from "../../utils/constants";
import visa from "../../assets/img/visa.png";
import master from "../../assets/img/card.png";
import iyzico from "../../assets/img/iyzico.png";


const WFooter = () => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["content"]}>
        <WLogo width={60} height={60} />

        <div className={styles["links"]}>
          <Link to={`/legal-terms/${terms.gizlilik}`}>Gizlilik Politikası</Link>
          <Link to={`/legal-terms/${terms.kullanimKosullari}`}>
            Kullanım Koşulları ve Üyelik sözleşmesi
          </Link>
        </div>
        <div className={styles["links"]}>
          <Link to={`/legal-terms/${terms.mesafeliSatisSozlesmesi}`}>
            Mesafeli Satış Sözleşmesi
          </Link>
          <Link to={`/legal-terms/${terms.caymaIptal}`}>
            Tüketici Hakları Cayma Iptal Iade Koşulları
          </Link>
        </div>
        <div className={styles["payment"]}>
          <img src={visa} alt="" />
          <img src={master} alt="" />

        </div>
      </div>

      <span className={styles["copyright"]}>
        Copyright © 2023 Teknodev LTD. All rights reserved.
      </span>
    </div>
  );
};

export default WFooter;
